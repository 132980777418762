<template>
    <div class="siteHolder">
      <Password />
    </div>
  </template>
  
  <script>
import Password from '@/components/Password.vue';

    export default {
        data () {
            return {
            }
        },
        methods: ({

        }),
        components: {
            Password,
        }
    }
  </script>
  
  
  <style lang="scss" scoped>
  @import "../assets/scss/variables.scss";
    .siteHolder {
      max-width: 1000px;
      margin: auto;
    }
  </style>
  