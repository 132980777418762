<template>
    <div class="siteHolder">
      <header>
        <section class="title-page">
          <div class="text">Przepisy</div>
        </section>
      </header>
  
      <body>
        <RecipesView/>
     </body>
    </div>
  </template>
  
  <script>
import RecipesView from '@/components/Recipes.vue'

    export default {
      data () {
        return {
            recipes: [],
            clickedId: '',
        }
      },
      methods: ({
        openRecipe(val) {
          console.log(val);
          if(this.clickedId == val){
            this.clickedId = '';
          } else {
            this.clickedId = val;
          }
        },
        convertText(text) {
          return text;
        }
      }),
      components: {
        RecipesView
      }
    }
  </script>
  
  
  <style lang="scss" scoped>
  @import "../assets/scss/variables.scss";
    .siteHolder {
      max-width: 1000px;
      margin: auto;
    }
  
    header {
      display: grid;
      grid-template-columns: min-content 0.2fr;
      margin-bottom: 20px;
      border-radius: 4px;
      background: linear-gradient(51deg, $color-1 0%, $color-2 63%, $color-2 100%);
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
    }
  
    .title-page {
      margin: 10px;
      grid-column: 4 / 8;
      font-size: 80px;
      font-weight: 300;
      .text {
        font-size: 70px;
        color: rgba($first-bg-color, 0.9);
        font-weight: 400;
        @media (max-width: 768px) {
          font-size: 50px;
        }
      }
    }

    .recipe {
      height: 88px;
      text-align: center;
      size: 34px;
      color: #434344;
      background-color: #80af9a;
      margin: 20px 20px 20px 20px;
      border-radius: 20px;
      cursor: pointer;
    }

    .recipe.active{
      height: 100%;
      background-color: #b7f8db;
    }

    .recipe-holder {
      margin: 10px 10px 10px 10px;
      padding-top: 15px;
    }

    .recipe-title {
      font-size: 46px;
    }

    .recipe-ingredients {
      margin: 20px 20px 20px 20px;
      text-align: left;
      font-size: 24px;
    }

    .recipe-description {
      margin: 20px 20px 20px 20px;
      text-align: left;
      font-size: 20px
    }

    .recipe-spacer {
      height: 30px;
    }
  </style>
  