<template>
    <div class="siteHolder">
      <body>
        <div class="border">
            <div class="inner-box">
                <div class="title-page">
                    <div class="text">MyHome</div>
                </div>
                <input type="text" class="user" name="usr" placeholder="Użytkownik">
                <input type="password" class="password" name="pwd" placeholder="Hasło" @keyup.enter="sendLogin">
                <div type="submit" class="button" @click="sendLogin">Zaloguj</div>
            </div>
        </div>
     </body>
    </div>
  </template>
  
  <script>
import axios from 'axios'
import variables from './../assets/scss/_variables.scss';
import router from "@/router/index.js";
import App from '@/App.vue';

    export default {
      data () {
        return {
            user: "",
            password: "",
        }
      },
      methods: ({
        async sendLogin() {
            var userField = document.querySelector(".user");
            var passwordField = document.querySelector(".password");
            let msg = userField.value + "." + passwordField.value
            const response = await axios.get('https://kosik.dynv6.net/webhooks/getdata.php?data=!login^' + msg + '&port=2223');
            this.totalVuePackages = response.data.total;
            console.log("login: " + response.data);
            if( response.data == "OK"){
                localStorage.setItem('isAuthenticated', true);
                this.$router.push({ name: 'home' });
            } else {
                passwordField.style.background = "#e98b8b";
            }
        },
      })
    }
  </script>
  
  
  <style lang="scss" scoped>
  @import "../assets/scss/variables.scss";
    .siteHolder {
      max-width: 1000px;
      margin: auto;
    }

    .title-page {
        margin: 10px;
        grid-column: 4 / 8;
        font-size: 80px;
        font-weight: 300;
        .text {
            font-size: 50px;
            color: $color-1;
            font-weight: 300;
        }
    }

    .border {
        width: 300px;
        height: 300px;
        border-radius: 4px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -200px;
        margin-left: -150px;
        background: linear-gradient(0deg, #199699 45%, #fec99b 50%, #199699 55%);
        background-size: 250% 250%;
        animation: gradientAnim 2s ease-in-out infinite alternate;

        @keyframes gradientAnim {
            0% {
                background-position: 50% 10%;
            }
            100% {
                background-position: 50% 90%;
            }
        }
        
        .inner-box {
            background-color: $second-bg-color;
            width: 99%;
            height: 99%;
            border-radius: 4px;
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }

        .user {
            width: 80%;
            padding: 12px 20px;
            margin: 20px 8px 8px 8px;
            border: 1px solid #ccc;
            border-radius: 10px;
            box-sizing: border-box;
            font-size: 16px;
        }
        
        .password {
            width: 80%;
            padding: 12px 20px;
            margin: 3px 8px 8px 8px;
            border: 1px solid #ccc;
            border-radius: 10px;
            box-sizing: border-box;
            font-size: 16px;
            }

        .button {
            color: $first-bg-color;
            font-size: 24px;
            font-weight: 600;
            display: inline-block;
            background-color: $color-2;
            border-radius: 10px;
            margin: 22px 10px 10px 10px;
            cursor: pointer;
            padding: 3px 35px 3px 35px;
            box-shadow: -1px 2px 7px 0px $first-bg-color;
            text-transform: uppercase;
            transition: all 0.2s ease-in-out;
            @media (max-width: 768px) {
            font-size: 20px;
            }

            &:hover {
            background: rgba($color-2, 80%);
            }

            &:active {
            color: rgba(255, 255, 255, 1);
            box-shadow: 0 5px 15px rgba(212, 212, 212, 0.4);
            }  
        }
        }

  </style>
  