<template>
  <router-view/>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;600&display=swap');

html {
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: 100%;
  background: radial-gradient(#116466, #2c3531, #2c3531);
}

body {
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: 100%;
}

#app {
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
