<template>
    <div class="chart-container">
        <div class="title-container">
            <div class="title">{{ varLabel }} </div>
            <div class="variable1"> {{ varActualData }}</div>
        </div>

        <div class="chart-box">
            <GChart
            type="LineChart"
            :data="SQLData"
            :options="chartOptions"
            :events="chartEvents"
            />
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { GChart } from 'vue-google-charts';

export default {
    props: {
        days: Number,
        tableName: String,
        varName: String,
        varLabel: String,
        color: String,
    },
    data () {
    return {
        minPowerWarning: 2.6, //below this value there will be a warning !!!
        SQLData: [],
        varActualData: 0,
        isWarning: false,
        chartOptions: {
          chart: {
            title: 'Climate',
            curveType: 'function',
            legend: { position: 'bottom' },
          },
          colors: [this.color],
          backgroundColor: '#157179',
          legend: 'none',
          hAxis: { textPosition: 'none' },
          chartArea: {width:"80%", height:"90%"},
        }
    }
    },
    methods: {
        getData() {
            console.log(this.table);
            axios.get('https://kosik.dynv6.net/webhooks/getClimateData.php?days=' + this.days + '&table=' + this.tableName + '&varName=' + this.varName + '&varLabel=' + this.varLabel).then(response => (this.SQLData = JSON.parse(JSON.stringify(response.data)), this.refreshData()));
        },
        refreshData () {
            console.log(this.SQLData);
            let unit = "";

            if (this.varLabel.includes("Temperatura")) {
                unit = "°C";
            } else if (this.varLabel.includes("Wilgotność")) {
                unit = "%";
            } else if (this.varLabel.includes("Ciśnienie")) {
                unit = " hPa";
            } else if (this.varLabel.includes("Światło")) {
                unit = " lux";
            } else if (this.varLabel.includes("Prędkość")) {
                unit = " km/h";
            } else if (this.varLabel.includes("Kierunek")) {
                unit = "°";
            } else {
                unit = "";
            }

            if (this.SQLData.length > 0) {
                this.varActualData = this.SQLData.pop()[1] + unit;
            } else {
                this.varActualData = "---";
            }

            console.log(this.varActualData);
        },
        getWarning(warn) {
            return warn ? 'warning-text' : 'normal-text';
        }
    },
    components: {
      GChart,
    },
    created () {
        this.intervalHolder = setInterval(this.getData, 60000);
    },
    mounted () {
        this.getData();
    },
    unmounted () {
      clearInterval(this.intervalHolder);
    },
    watch: {
        days(newValue, oldValue) {
            this.getData();
        }
    },
    chartEvents: {
        'select': () => {
          // handle event here
        }
      }
}
</script>


<style lang="scss" scoped>
@import "../assets/scss/variables.scss";


.section-wrapper {
    display: grid;
    grid-template-columns: 70% 27%;
    gap: 30px;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

.chart-container {
    padding: 10px;
    text-align: center;
    background-color: $color-0;
    border-radius: 4px;
    border: $border-1 1px solid;
}

.chart-box {
    padding: 10px;
    text-align: center;
    background-color: $color-0;
    border-radius: 4px;
}

.title-container {
    text-align: center;
    margin-bottom: 10px;
    border-bottom: $first-bg-color 1px solid;
}

.title {
    display: inline-block;
    font-size: 38px;
    text-align: center;
    color: $color-3;
    line-height: 1.2em;
    margin: 0px 10px 0px 0px;
}

.variable1 { 
    display: inline-block;
    font-size: 24px;
    margin-bottom: 10px; 
    color: $color-1;
    margin: 0px 0px 0px 10px;
}

.text {
    padding-left: 10px;
    font-size: 18px;
    color: $color-2;
    line-height: 1.6;
}

.description {
    margin: 2px;
}

.warning-text {
  color: #7e2e0e;
  font-weight: bold;
  display: inline;
}

.normal-text {
  color: #2c3e50;
  display: inline;
}

.test {
    color: #a4c6ec;
}
</style>
  