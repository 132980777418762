<template>
    <div class="siteHolder">
        <div class="log-container">
            <div class="text">
                <pre>{{ logData }}</pre>
            </div>
        </div>
    </div>
  </template>
  
  <script>
import axios from 'axios'
import tasmota from '@/components/Tasmota.vue';

    export default {
      data () {
        return {
            logData: "",
            intervalHolder: 0,
        }
      },
      methods: ({
        getData () {
          axios.get('https://kosik.dynv6.net/webhooks/getLog.php').then(response => (this.logData = response.data));
        },
      }),
      created () {
        this.getData();
        this.intervalHolder = setInterval(this.getData, 5000);
      },
      unmounted () {
        clearInterval(this.intervalHolder);
      }
    }
  </script>
  
  
  <style lang="scss" scoped>
  @import "../assets/scss/variables.scss";
    .siteHolder {
        max-width: 1000px;
        margin: auto;
    }

    .log-container {
        padding: 10px;
        background-color: $color-0;
        
        .text {
            text-align: left;
            color: $color-3;
        }
    }

    pre {
        white-space: pre-wrap;
    }

  </style>
  