<template>
    <div class="siteHolder">
      <body>
        <div class="section-wrapper">

          <div class="select-container">
            <div class="title">Dni:</div> 
            <select class="chooseBox" v-model="selectedDays" id="days" name="days">
              <option v-for="option in options" :value="option.value">
                {{ option.text }}
              </option>
            </select>
          </div>
          <div class="flower-container"><flowerChart v-if="renderComponent" flowerName="Bonsai" flowerNumber="1" :flowerDays="selectedDays"/></div>
          <div class="flower-container"><flowerChart flowerName="Strelicja" flowerNumber="2" :flowerDays="selectedDays"/></div>
          <div class="flower-container"><flowerChart flowerName="Szeflera" flowerNumber="3" :flowerDays="selectedDays"/></div>

        </div>       
     </body>
    </div>
  </template>
  
  <script>
import axios from 'axios'
import variables from './../assets/scss/_variables.scss';
import flowerChart from '@/components/FlowerChart.vue';

    export default {
      data () {
        return {
          intervalHolder: 0,
          myHomeData: [],
          devicesData: [],
          selectedDays: 7,
          options: [
            { text: "1 dzień", value: 1 },
            { text: "3 dni", value: 3 },
            { text: "7 dni", value: 7 },
            { text: "14 dni", value: 14 },
            { text: "30 dni", value: 30 }
          ],
          renderComponent: true,
        }
      },
      methods: ({
        
      }),
      components: {
        flowerChart,
      },
      created () { /* on load */ 
      },
      unmounted () {
      }
    }
  </script>
  
  
  <style lang="scss" scoped>
  @import "../assets/scss/variables.scss";
    .siteHolder {
      max-width: 1000px;
      margin: auto;
    }

    .section-wrapper {
      display: grid;
      grid-template-columns: 0.33fr 0.33fr 0.33fr;
      gap: 10px;
      @media (max-width: 1000px) {
        grid-template-columns: 0.5fr 0.5fr;
      }
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
    }

    .device-container {
      padding: 10px;
      text-align: center;
      background-color: $color-0;
      border-radius: 4px;
      border: $border-1 1px solid;
      line-height: 1.6;

      .title {
        margin-bottom: 10px;
        font-size: 38px;
        color: $color-3;
        border-bottom: $first-bg-color 1px solid;
      }
      .text {
        font-size: 28px;
        text-align: center;
        color: $color-2;
      }
      .text2 {
        font-size: 18px;
        text-align: center;
        color: $color-2;
      }
      .button {
        color: $first-bg-color;
        font-size: 24px;
        font-weight: 600;
        display: inline-block;
        background-color: $color-2;
        border-radius: 10px;
        margin: 10px;
        cursor: pointer;
        padding: 0px 30px 0px 30px;
        box-shadow: -1px 2px 7px 0px $first-bg-color;
        text-transform: uppercase;
        transition: all 0.2s ease-in-out;
        @media (max-width: 768px) {
          font-size: 20px;
        }

        &:hover {
          background: rgba($color-2, 80%);
        }

        &:active {
          color: rgba(255, 255, 255, 1);
          box-shadow: 0 5px 15px rgba(212, 212, 212, 0.4);
        }  
      }

      .color-picker {
        width: 10%;
        padding-top: 10%;
        border-radius: 50%;
        box-shadow: 1px 2px 5px 0px;
        cursor: pointer;
        display: inline-block;
        margin: 3px 3px 3px 3px;
        
        &:active {
          color: rgba(255, 255, 255, 1);
          box-shadow: 0 5px 15px rgba(145, 92, 182, .4);
        } 
      }

      .slider {
        -webkit-appearance: none;
        width: 100%;
        height: 15px;
        border-radius: 5px;  
        background: $first-bg-color;
        outline: none;
        opacity: 0.8;
        margin: 20px 0px 20px 0px;
        transition: opacity .2s;

        &:hover {
          opacity: 1; /* Fully shown on mouse-over */
        }
      }

      .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        border-radius: 50%; 
        background: $color-4;
        cursor: pointer;
      }

      .slider::-moz-range-thumb {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: $color-4;
        cursor: pointer;
      }
  }

  .flower-container {
    grid-column: span 4;
    grid-row-end: span 2;
  }

  .select-container {
    grid-column: 1 / 5;
    grid-row-end: span 1;
    padding: 10px;
    text-align: center;
    background-color: $color-0;
    border-radius: 4px;
    border: $border-1 1px solid;

    .title {
        display: inline;
        margin-bottom: 10px;
        margin-right: 20px;
        padding-right: 20px;
        font-size: 38px;
        color: $color-3;
        border-right: $first-bg-color 1px solid;
      }

    .chooseBox {
      font-size: 24px;
    }
  }
  </style>
  