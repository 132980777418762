<template>
  <div class="home-view">
    <MainView/>
  </div>
</template>

<script>
// @ is an alias to /src
import MainView from '@/components/MainView.vue'

export default {
  name: 'HomeView',
  components: {
    MainView
  }
}
</script>
