<template>
    <div class="tasmota-box">
        <div class="title">{{ tasmotaName }}</div>
        <div class="text">Napiecie: {{ energyVoltage }} V</div>
        <div class="text">Moc: {{ energyPower }} W</div>
        <div class="text">Zuzycie: {{ energyTotal.toFixed(3) || 0 }} kWh</div>
        <div class="text">Koszt: {{ energyCost.toFixed(2) || 0 }} eur</div>

        <div class="chart-box">
            <GChart
            type="LineChart"
            :data="chartData"
            :options="chartOptions"
            :events="chartEvents"
            />
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { GChart } from 'vue-google-charts';

export default {
    data () {
    return {
        tasmotaData: "",
        tasmotaName: "",
        energyArray: [],
        energyTotal: 0,
        energyPower: 0,
        energyVoltage: 0,
        energyCost: 0,
        intervalHolder: 0,
        chartData: [],
        chartOptions: {
          chart: {
            title: 'Energia',
            curveType: 'function',
            legend: { position: 'bottom' },
          },
          colors: ['#ffffff','#909090'],
          backgroundColor: '#157179',
          legend: 'none',
          hAxis: { textPosition: 'none' },
          chartArea: {width:"80%", height:"90%"},
        }
    }
    },
    methods: ({
        getData() {
            axios.get('https://kosik.dynv6.net/webhooks/getdata.php?data=!getTasmotaData&port=2223').then(response => (this.tasmotaData = JSON.parse(JSON.stringify(response.data)), this.refreshData()));
        },
        refreshData () {
            console.log(this.energyArray);
            this.tasmotaName = this.tasmotaData["name"];
            this.energyTotal = this.tasmotaData["energyTotal"];
            this.energyPower = this.tasmotaData["power"];
            this.energyVoltage = this.tasmotaData["voltage"];
            this.energyCost = this.energyTotal * 0.37;
            this.energyArray = this.tasmotaData["energyArray"];
            this.chartData[0] = ['Time', 'Power'];
            for(let i=0; i<this.energyArray.length; i++){
                this.chartData[i+1] = [this.energyArray[i][0], parseInt(this.energyArray[i][1])];
            }
        }
    }),
    components: {
      GChart,
    },
    created () {
        this.intervalHolder = setInterval(this.getData, 10000);
    },
    mounted () {
        this.getData();
    },
    unmounted () {
      clearInterval(this.intervalHolder);
    }
}
</script>


<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
.tasmota-box {
    padding: 10px;
    text-align: center;
    background-color: $color-0;
    border-radius: 4px;
    border: $border-1 1px solid;
}

.title {
    font-size: 38px;
    text-align: center;
    margin-bottom: 10px;
    color: $color-3;
    border-bottom: $first-bg-color 1px solid;
    line-height: 1.7em;
}

.text {
    padding-left: 10px;
    font-size: 18px;
    color: $color-2;
    line-height: 1.6;
}

.chart-box {
    padding: 0px;
}
</style>
  