<template>
    <div class="clock-box">
        <div class="time">
            {{ myTime }}
        </div>
        <div class="date">
            {{ myDay }}, {{ myDayNr }}, {{ myMonth }}
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { GChart } from 'vue-google-charts';

export default {
    data () {
    return {
        myTime: 0,
        myDay: "",
        myDayNr: 0,
        myMonth: "",
        intervalHolder: 0,
    }
    },
    methods: ({
        mytime() {
            let dataStamp = new Date();
            this.myTime = dataStamp.toLocaleTimeString();
            this.myDayNr = dataStamp.getDate();
            switch (dataStamp.getDay()) {
            case 0:
                this.myDay = "niedziela";
                break;
            case 1:
                this.myDay = "poniedziałek";
                break;
            case 2:
                this.myDay = "wtorek";
                break;
            case 3:
                this.myDay = "środa";
                break;
            case 4:
                this.myDay = "czwartek";
                break;
            case 5:
                this.myDay = "piątek";
                break;
            case 6:
                this.myDay = "sobota";
            }
            switch (dataStamp.getMonth()) {
            case 0:
                this.myMonth = "Stycznia";
                break;
            case 1:
                this.myMonth = "Lutego";
                break;
            case 2:
                this.myMonth = "Marca";
                break;
            case 3:
                this.myMonth = "Kwietnia";
                break;
            case 4:
                this.myMonth = "Maja";
                break;
            case 5:
                this.myMonth = "Czerwca";
                break;
            case 6:
                this.myMonth = "Lipca";
                break;
            case 7:
                this.myMonth = "Sierpnia";
                break;
            case 8:
                this.myMonth = "Września";
                break;
            case 9:
                this.myMonth = "Października";
                break;
            case 10:
                this.myMonth = "Listopada";
                break;
            case 11:
                this.myMonth = "Grudnia";
            }
        }
    }),
    created () {
        this.mytime();
        this.intervalHolder = setInterval(this.mytime, 1000);
    },
    unmounted () {
      clearInterval(this.intervalHolder);
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
.clock-box {
    padding: 10px;
    text-align: center;
    background-color: $color-0;
    border-radius: 4px;
    border: $border-1 1px solid;
}

.time {
    font-size: 50px;
    text-align: center;
    color: $color-3;
}

.date {
    font-size: 20px;
    text-align: center;
    color: $color-2;
}

</style>