<template>
    <div class="siteHolder">
      <body>

        <div v-for="recipe in recipes" :key="recipe" class="recipe-container" :class="[{'active': currentRecipeName === recipe}]">
          <div class="recipe">
            <div class="title" @click="getRecipeFromServer(recipe)"> {{ recipe }} </div>

            <div class="divider">
              <div class="ingredients-container" v-if="recipe == currentRecipeName">
                <div class="ingredients" v-for="(ingredient, index) in activeRecipe.ingredients"> - {{ ingredient }}</div>
              </div>
              <div class="photo-container" v-if="recipe == currentRecipeName">
                <img v-show="show" @load="show=true" :src="photo" id="photo" v-if="getImgUrl(currentRecipeName)"/> 
              </div>
            </div>
            <div class="spacer" v-if="recipe == currentRecipeName"></div>
            <div class="description" v-for="(descript, index) in activeRecipe.description" v-if="recipe == currentRecipeName"> {{ index + 1 }}.  {{ descript }} </div>
            <div class="buttons-holder">
              <div class="button b1" @click="editRecipe"  v-if="recipe == currentRecipeName">
                <i class="icon-pencil"/></div>
              <div class="button b2" @click="deleteRecipe"  v-if="recipe == currentRecipeName">
                <i class="icon-trash"/></div>
            </div>


          </div>
        </div>
        
        <div class="button add-recipe" @click="addRecipe">
          <i class="icon-plus"/>
        </div>

        <Teleport to="body">
          <modal :show="showModal" :recipeTitle="currentRecipeTitle" :recipeIngredients="currentRecipeIngredients" :recipeDescription="currentRecipeDescription"  @close="closeModal">
          </modal>
        </Teleport>
     </body>
    </div>
  </template>
  
  <script>
import Modal from './RecipeEdit.vue'
import axios from 'axios'

    export default {
      data () {
        return {
            recipes: [],
            activeRecipe: {},
            currentRecipeId: 0,
            currentRecipeName: "",
            currentRecipePosition: -1,
            showModal: false,
            currentRecipeTitle: "",
            currentRecipeIngredients: "",
            currentRecipeDescription: "",
            photo: null,
        }
      },
      methods: ({
        getImgUrl(pic) {
          try {
            var photoAddress = './../recipes/' + pic + '.jpg';
            this.photo = photoAddress.replace(" ","%20");
            console.log(this.photo);
            //this.photo = require('./../assets/recipes/' + pic + '.jpg');
            return true;
          }
          catch (e) {
            return false;
          }
        },
        openRecipe() {
          this.currentRecipeId = this.activeRecipe.id;
          this.currentRecipeTitle = this.activeRecipe.title;
          this.currentRecipeIngredients = this.activeRecipe.ingredients;
          this.currentRecipeDescription = this.activeRecipe.description;
        },
        convertText(text) {
          return text;
        },
        editRecipe: function () {
          this.showModal = true
        },
        addRecipe: function() {
          this.activeRecipe.id = 999;
          this.activeRecipe.title = "";
          this.activeRecipe.ingredients = [];
          this.activeRecipe.description = [];
          this.openRecipe();
          this.showModal = true;
        },
        deleteRecipe: function() {
          let text = "Na pewno chcesz skasować ten przepis?";
          if (confirm(text) == true) {
              this.deleteRecipe();
              alert("Przepis został skasowany");
          }   
          this.activeRecipe.id = 999;
        },
        splitText: function (text) {
            var separator = text.indexOf('\n') !== -1 ? '\n' : ',';
            var myarray = text.toString().split(separator);
            for(var i=0; i<myarray.length; i++){
                var oneChar = myarray[i];
                if(oneChar.charAt(0) === ' '){
                    myarray[i] = oneChar.slice(1, oneChar.length);
                }
            }
            return myarray;
        },
        closeModal: function(recipeTitle, recipeIngredients, recipeDescription) {
          this.activeRecipe.title = recipeTitle;
          this.activeRecipe.ingredients = this.splitText(recipeIngredients);
          this.activeRecipe.description = this.splitText(recipeDescription);
          this.saveRecipe();
          this.showModal = false;
          this.currentRecipePosition = -1;
          this.getRecipesTitles()
          setTimeout(this.getRecipesTitles(), 2000); 
          this.currentRecipeName = "";
        },
        deleteRecipe() {
          const response = axios.post('https://kosik.dynv6.net/webhooks/deleteRecipe.php?data=' + JSON.stringify(this.activeRecipe));
          this.currentRecipePosition = -1;
          setTimeout(this.getRecipesTitles(), 2000); 
          this.currentRecipeName = "";
        },
        saveRecipe() {
          console.log(this.activeRecipe);
          console.log(JSON.stringify(this.activeRecipe));
          const response = axios.post('https://kosik.dynv6.net/webhooks/writeRecipe.php?data=' + JSON.stringify(this.activeRecipe));
        },
        getRecipeFromServer(val) {
          this.currentRecipeName = val;
          axios.get('https://kosik.dynv6.net/webhooks/readRecipe.php?title=' + val).then(response => (this.activeRecipe = JSON.parse(JSON.stringify(response.data)), this.openRecipe(val)));
        },
        getRecipesTitles() {
          axios.get('https://kosik.dynv6.net/webhooks/readRecipesTitles.php').then(response => (this.recipes = JSON.parse(JSON.stringify(response.data)), this.recipes.sort(), console.log(this.recipes)));
        }
      }),
      components: {
        Modal
      },
      async mounted() {
        //const response = await axios.get("https://kosik.dynv6.net/webhooks/readRecipes.php");
        //this.info = response.data;
      },
      created() {
        axios.get('https://kosik.dynv6.net/webhooks/readRecipesTitles.php').then(response => (this.recipes = JSON.parse(JSON.stringify(response.data)), this.recipes.sort(), console.log(this.recipes)))
      }
    }
  </script>
  
  
  <style lang="scss" scoped>
  @import "../assets/css/fontello.css";
  @import "../assets/scss/variables.scss";
    .siteHolder {
      max-width: 1000px;
      margin: auto;
    }

    .recipe {
      text-align: center;
      color: $first-bg-color;
      background-color: $color-0;
      border: $border-1 1px solid;
      /*background-image: linear-gradient(to right, rgba($color-0, 0.1) 0%, $color-0 20%, $color-0 80%, rgba($color-0, 0.1) 100%);*/
      margin: 15px 5px 15px 5px;
      border-radius: 6px;
      cursor: pointer;
  
      .title {
        color: $color-3;
        font-size: 46px;
        padding: 7px 10px 10px 10px;
      }

      .divider {
        display: grid;
        grid-template-columns: minmax(60%, max-content);
        @media (max-width: 768px) {
          grid-template-columns: 1fr;
        }
      }
  
      .ingredients {
        margin: 20px 20px 20px 20px;
        text-align: left;
        font-size: 24px;
        color: $color-2;
      }

      .ingredients-container {
        grid-column: 1 / 1;
      }
      .photo-container {
        grid-column: 2 / 2;
        #photo{
          display: block;
          max-width: 85%;
          max-height: 85%;
          width: auto;
          height: auto;
          margin: 25px 10px 0px 10px;
          border: $color-2 2px solid;
          border-radius: 6px;
          box-shadow: -1px 2px 7px 0px;
        }
      }
      
      .description {
        margin: 20px 20px 20px 20px;
        text-align: left;
        font-size: 20px;
        color: $color-4;
      }
  
      .spacer {
        height: 30px;
      }
    }

    .recipe.active{
      height: 100%;
      background-image: none;
      background-color: $color-4;
    }


    .buttons-holder{
      display: grid;
      grid-template-columns: repeat( 12, 1fr) 10px;
      justify-items: center;
    }

    .button {
      margin: 10px;
      border-radius: 4px;
      padding: 10px;
      --size-font: 200%;

      :active {
        background-color: #ffffff;
      }      
      .text-anim {
        float: left;
        width: 70%;
        font-size: var(--size-font);
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        animation: 0.4s anim-out forwards;
      }

      [class^="icon-"] {
        color: #f6efe3;
        font-size: var(--size-font);
        position: relative;
        top: 10%;
        transition: all .3s ease-in-out;
      }
      .text {
        margin-right: 8px;
        float: right;
        width: 15%;
        font-size: var(--size-font);
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .b1 {
      grid-column: 11 / 11;
      $b1-color: #9cb129;
      background: rgba($b1-color, 20%);
      border: 2px rgba($b1-color, 100%) solid;
      box-shadow: -1px 2px 7px 0px;
      &:hover {
        background: rgba($b1-color, 70%);
      }
    }

    .b2 {
      grid-column: 12 / 12;
      $b1-color: #b23c3c;
      background: rgba($b1-color, 20%);
      border: 2px rgba($b1-color, 100%) solid;
      box-shadow: -1px 2px 7px 0px;
      &:hover {
        background: rgba($b1-color, 70%);
      }
    }

    .add-recipe {
      grid-column: 12 / 12;
      $b1-color: $color-3;
      background: rgba($b1-color, 20%);
      border: 2px rgba($b1-color, 100%) solid;
      display: block;
      margin: 15px 5px 15px 5px;
      border-radius: 6px;
      cursor: pointer;

      &:hover {
        background: rgba($b1-color, 70%);
      }
    }
  </style>
  