<template>
    <div class="siteHolder">
      <header>
        <section class="title-page">
          <div class="text">Terrarium</div>
        </section>
      </header>
  

      <TerrariumView/>
    </div>
  </template>
  
  <script>
import TerrariumView from '@/components/Terrarium.vue'

    export default {
      data () {
        return {
        }
      },
      methods: ({
        
      }),
      components: {
        TerrariumView,
      }
    }
  </script>
  
  
  <style lang="scss" scoped>
  @import "../assets/scss/variables.scss";
    .siteHolder {
      max-width: 1000px;
      margin: auto;
    }
  
    header {
      display: grid;
      grid-template-columns: min-content 0.2fr;
      margin-bottom: 20px;
      border-radius: 4px;
      background: linear-gradient(51deg, $color-1 0%, $color-2 63%, $color-2 100%);
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
    }
  
    .title-page {
      margin: 10px;
      grid-column: 4 / 8;
      font-size: 80px;
      font-weight: 400;
      .text {
        font-size: 70px;
        color: rgba($first-bg-color, 0.9);
        font-weight: 400;
        @media (max-width: 768px) {
          font-size: 50px;
        }
      }
    }
  </style>
  