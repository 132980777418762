<template>
    <div class="flower-box">
        <div class="title">{{ terraSensorName }}</div>

        <div class="section-wrapper">
            <div class="chart-box">
                <GChart
                type="LineChart"
                :data="terraData"
                :options="chartOptions"
                :events="chartEvents"
                />
            </div>
            <div class="description">
            Temperatura: {{ temperature }}°C<br>
            Wilgotność: {{ humidity }}%<br>
            <div v-if="updn === 'UP'">UVI: {{ uvi }}</div></div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { GChart } from 'vue-google-charts';

export default {
    props: {
        updn: String,
        terraDays: Number,
    },
    data () {
    return {
        terraData: [],
        temperature: 0,
        humidity: 0,
        uvi: 0,
        terraSensorName: "",
        isWarning: false,
        chartOptions: {
          chart: {
            title: 'Terrarium',
            curveType: 'function',
            legend: { position: 'bottom' },
          },
          colors: ['#a4c6ec','#f0c78b', '#549251'],
          backgroundColor: '#157179',
          legend: 'none',
          hAxis: { textPosition: 'none' },
          chartArea: {width:"80%", height:"90%"},
        }
    }
    },
    methods: ({
        getData() {
            axios.get('https://kosik.dynv6.net/webhooks/getTerraData.php?table=' + this.updn + '&days=' + this.terraDays).then(response => (this.terraData = JSON.parse(JSON.stringify(response.data)), this.refreshData()));
        },
        refreshData () {
            var lastItem = this.terraData.pop();

            this.temperature = lastItem[1];
            this.humidity = lastItem[2];
            this.uvi = lastItem[3];
        }
    }),
    components: {
      GChart,
    },
    created () {
        this.intervalHolder = setInterval(this.getData, 60000);
        if(this.updn == "UP"){
            this.terraSensorName = "góra";
        } else if (this.updn == "DN"){
            this.terraSensorName = "dół";
        }
    },
    mounted () {
        this.getData();
    },
    unmounted () {
      clearInterval(this.intervalHolder);
    },
    watch: {
        terraDays(newValue, oldValue) {
            this.getData();
        }
    },
    chartEvents: {
        'select': () => {
          // handle event here
        }
      }
}
</script>


<style lang="scss" scoped>
@import "../assets/scss/variables.scss";


.section-wrapper {
    display: grid;
    grid-template-columns: 70% 27%;
    gap: 30px;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
.flower-box {
    padding: 10px;
    text-align: center;
    background-color: $color-0;
    border-radius: 4px;
    border: $border-1 1px solid;
}

.title {
    font-size: 38px;
    text-align: center;
    margin-bottom: 10px;
    color: $color-3;
    border-bottom: $first-bg-color 1px solid;
    line-height: 1.7em;
}

.text {
    padding-left: 10px;
    font-size: 18px;
    color: $color-2;
    line-height: 1.6;
}

.chart-box {
    padding: 0px;
}

.description {
    margin: 2px;
}

.test {
    color: #a4c6ec;
}
</style>
  