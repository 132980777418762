<template>
    <div class="tasmota-box">
        <div class="title">Błędy</div>
        <ul>
            <li class="error" v-for="item in errorsArray.errors" :key="item">{{ item }}</li>
        </ul>
    </div>
</template>

<script>
import axios from 'axios';
import { GChart } from 'vue-google-charts';

export default {
    data () {
    return {
        errorsArray: "",
    }
    },
    methods: ({
        getData() {
            axios.get('https://kosik.dynv6.net/webhooks/getdata.php?data=!getErrorsData&port=2223').then(response => (this.errorsArray = JSON.parse(JSON.stringify(response.data)), this.refreshData()));
        },
        refreshData () {
            console.log(this.errorsArray);
        }
    }),
    components: {
      GChart,
    },
    created () {
        this.intervalHolder = setInterval(this.getData, 10000);
    },
    mounted () {
        this.getData();
    },
    unmounted () {
      clearInterval(this.intervalHolder);
    }
}
</script>


<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
.tasmota-box {
    padding: 10px;
    text-align: center;
    background-color: $color-0;
    border-radius: 4px;
    border: $border-1 1px solid;
}

.title {
    font-size: 38px;
    text-align: center;
    margin-bottom: 10px;
    color: $color-3;
    border-bottom: $first-bg-color 1px solid;
    line-height: 1.7em;
}

.error {
    padding-left: 10px;
    font-size: 18px;
    color: $color-2;
    line-height: 1.6;
}
</style>
  