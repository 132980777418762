
<script>
export default {
  props: {
    show: Boolean,
    recipeTitle: String,
    recipeIngredients: String,
    recipeDescription: String,
  }
}
</script>

<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="recipe-container">
            <div class="label">Przepis:</div>
            <textarea class="editBox title" v-model="recipeTitle" placeholder="co gotujemy?" />
          </div>

          <div class="recipe-container">
            <div class="label">Składniki:</div>
            <textarea class="editBox ingredients" v-model="recipeIngredients" placeholder="składniki" />
          </div>
          <div class="recipe-container">
            <div class="label">Opis:</div>
            <textarea class="editBox description" v-model="recipeDescription" placeholder="opis" />
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <div class="button" @click="$emit('close', recipeTitle, recipeIngredients, recipeDescription)">OK</div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-footer {
  height: 48px;
}

.modal-container {
  width: 600px;
  margin: 0px auto;
  padding: 20px 20px;
  background-color: $first-bg-color;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.7s ease;
}

.recipe-container {
  margin-bottom: 20px;
  background-color: $color-0;
  border-radius: 15px;
  padding: 12px;
  box-shadow: 0 4px 14px rgb(0 20 0 / 33%);
}

.button {
  float: right;
  margin: 10px;
  width: 50px;
  height: 40px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.label {
  font-size: 32px;
  color: $color-2;
  text-align: center;
  margin-bottom: 10px;
  font-weight: 400;
}

.editBox {
  font-size: 20px;
  width: 99%;
  border-radius: 7px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: $first-bg-color;
  background-color: #effbf5;
}
.editBox.title {
  height: 32px;
  }
.editBox.ingredients {
  height: 120px;
}
.editBox.description {
  height: 200px;
}

.button {
  grid-column: 12 / 12;
  height: 48px;
  border-radius: 4px;
  $b1-color: $color-3;
  background: rgba($b1-color, 50%);
  border: 2px rgba($b1-color, 100%) solid;
  font-size: 35px;
  padding-left: 26px;
  padding-right: 29px;
  cursor: pointer;
  
  &:hover {
    background: rgba($b1-color, 20%);
  }
  :active {
    background-color: #ffffff;
  }      
}
</style>