import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Recipes from '../views/RecipesView.vue'
import Devices from '../views/DevicesView.vue'
import Climate from '../views/ClimateView.vue'
import Flowers from '../views/FlowersView.vue'
import Terrarium from '../views/TerraView.vue'
import Log from '../views/LogView.vue'
import Diagnostics from '../views/DiagnosticsView.vue'
import Password from '../views/PasswordView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/',
    name: 'recipes',
    component: Recipes
  },
  {
    path: '/',
    name: 'devices',
    component: Devices
  },
  {
    path: '/',
    name: 'climate',
    component: Climate
  },
  {
    path: '/',
    name: 'flowers',
    component: Flowers
  },
  {
    path: '/',
    name: 'terrarium',
    component: Terrarium
  },
  {
    path: '/',
    name: 'log',
    component: Log
  },
  {
    path: '/',
    name: 'diagnostics',
    component: Diagnostics
  },
  {
    path: '/',
    name: 'login',
    component: Password
  }
  /*,
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AboutView.vue')
  }*/
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
