<template>
  <div class="home">
    <header>
      <section class="title-page">
        <div class="text">MyHome</div>
      </section>
    </header>

    <div class="section-wrapper">
        <section class="tabs-container">
          <section class="clock">
            <clock />
          </section>

          <section class="temperature">
            <div class="title">Na zewnątrz</div>
            <div class="text">Temperatura: {{ sensorOutside.temperature }}°C</div>
            <div class="text2">Wilgotność: {{ sensorOutside.humidity }}%</div>
            <div class="text2">Ciśnienie: {{ sensorOutside.airPressure }} hPa</div>
          </section>

          <section class="temperature">
            <div class="title">Salon</div>
            <div class="text">Temperatura: {{ salon.temperature }}°C</div>
            <div class="text2">Wilgotność: {{ salon.humidity }}%</div>
          </section>

          <div class="tasmota-container"><tasmota /></div>
          
          <div class="device-holder">
            <div class="title">Motywy</div>
            <div class="button" @click="sendMsg('themeSleep')">Śpij</div>
            <div class="button" @click="sendMsg('themeRomantic')">Romantyczny</div>
          </div>
          
          <div class="errors-container"><errors /></div>
        </section>
        
        <section class="buttons-container">
          <div class="button" @click="$router.push({ name: 'devices' })"><div class="text">Urządzenia</div></div>
          <div class="button" @click="$router.push({ name: 'climate' })"><div class="text">Klimat</div></div>
          <div class="button" @click="$router.push({ name: 'flowers' })"><div class="text">Kwiaty</div></div>
          <div class="button" @click="$router.push({ name: 'terrarium' })"><div class="text">Terrarium</div></div>
          <div class="button" @click="$router.push({ name: 'recipes' })"><div class="text">Przepisy</div></div>
          <div class="button" @click="$router.push({ name: 'diagnostics' })"><div class="text">Diagnostyka</div></div>
          <div class="button" @click="$router.push({ name: 'log' })"><div class="text">Log</div></div>
        </section>
        
      </div>

  </div>
</template>

<script>
import axios from 'axios';
import tasmota from '@/components/Tasmota.vue';
import errors from '@/components/Errors.vue';
import clock from '@/components/Clock.vue';
import router from "@/router/index.js";

  export default {
    data () {
      return {
        isAuth: false,
        myHomeData: [],
        intervalHolder: 0,
        salon: {
          temperature: 0,
          humidity: 0,
          power: 0,
        },
        sensorOutside: {
          temperature: 0,
          humidity: 0,
          power: 0,
          light: 0,
          windSpeed: 0,
        },
        myHomeData: [],
      }
    },
    components: {
      tasmota,
      errors,
      clock,
    },
    methods: ({
      changeVariable () {
        this.isAuth = localStorage.getItem('isAuthenticated');
      },
      async sendMsg(msg) {
        console.log("sending msg...");
        const response = await axios.get('https://kosik.dynv6.net/webhooks/getdata.php?data=!set^' + msg + '&port=2223');
        this.totalVuePackages = response.data.total;
        console.log(response);
      },
      refreshData(){
        console.log(this.myHomeData);
        this.myHomeData.forEach(obj => {
          switch (obj.name) {
            case "Salon":
              this.salon = obj;
              break;
            case "Sypialnia":
              this.sypialnia = obj;
              break;
            case "sensorOutside":
              this.sensorOutside = obj;
              break;
            default:
              break;
          }
        });
      },
      getData () {
        axios.get('https://kosik.dynv6.net/webhooks/getdata.php?data=!getMyHomeData&port=2223').then(response => (this.myHomeData = JSON.parse(JSON.stringify(response.data)), this.refreshData()));
      }
    }),
    created () {
      if (localStorage.getItem('isAuthenticated') == "false"){
        this.$router.push({ name: 'login' });
      }
      this.getData();
      this.intervalHolder = setInterval(this.getData, 5000);
    },
    unmounted () {
      clearInterval(this.intervalHolder);
    }
  };
</script>


<style lang="scss" scoped>
@import "../assets/css/fontello.css";
@import "../assets/scss/variables.scss";

  .home {
    max-width: 1000px;
    margin: auto;
  }

  header {
      display: grid;
      grid-template-columns: min-content 0.2fr;
      margin-bottom: 20px;
      border-radius: 4px;
      background: linear-gradient(51deg, $color-1 0%, $color-2 63%, $color-2 100%);
      position: relative;
      overflow: hidden;
  }

  .title-page {
    margin: 10px;
    grid-column: 4 / 8;
    font-size: 80px;
    font-weight: 300;
    .text {
      font-size: 70px;
      color: rgba($first-bg-color, 0.9);
      font-weight: 400;
      @media (max-width: 768px) {
        font-size: 50px;
      }
    }
  }

  .section-wrapper {
    display: grid;
    grid-template-columns: 70% 27%;
    gap: 30px;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .tabs-container {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 10px;

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }

  .device-holder {
    font-size: 36px;
    padding: 10px;
    text-align: center;
    background-color: $color-0;
    border-radius: 4px;
    border: $border-1 1px solid;

    .title {
      font-size: 38px;
      text-align: center;
      display: block;
      color: $color-3;
      line-height: 1.7em;
      border-bottom: $first-bg-color 1px solid;  
      margin-bottom: 18px;  
    }

    .button {
      font-size: 24px;
      display: inline-block;
      background-color: $color-2;
      border-radius: 10px;
      margin: 10px;
      font-weight: 600;
      padding: 2px 25px 2px 25px;
      cursor: pointer;
      box-shadow: -1px 2px 7px 0px;
      text-transform: uppercase;
      @media (max-width: 768px) {
        font-size: 20px;
        padding: 5px 30px 5px 30px;
      }
      transition: all 0.2s ease-in-out;

      &:hover {
        background: rgba($color-2, 80%);
      }

      &:active {
        color: rgba(255, 255, 255, 1);
        box-shadow: 0 5px 15px rgba(145, 92, 182, .4);
      } 
    }
  }
  
  .clock {
    grid-column: 1 / span 2;

    @media (max-width: 600px) {
      grid-column: 1 / 1;
    }
  }

  .temperature {
    padding: 10px;
    text-align: center;
    background-color: $color-0;
    border-radius: 4px;
    border: $border-1 1px solid;
    line-height: 1.6;
    .title {
      margin-bottom: 10px;
      font-size: 38px;
      color: $color-3;
      border-bottom: $first-bg-color 1px solid;
    }
    .text {
      font-size: 28px;
      text-align: center;
      color: $color-2;
    }
    .text2 {
      font-size: 18px;
      text-align: center;
      color: $color-2;
    }
  }

  .tasmota-container {
    grid-row-end: span 2;
  }
  .errors-container {
    grid-row-end: span 2;
  }

  .button {
    background-color: $color-1;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: -1px 2px 7px 0px;
    text-transform: uppercase;
    margin-bottom: 20px;
    .text {
      text-align: center;
      padding: 15px 0px 15px 0px;
      font-size: 34px;
      color: $first-bg-color;
      font-weight: 400;
      @media (max-width: 768px) {
        font-size: 26px;
      }
    }
  }
</style>
