<template>
    <div class="flower-box">
        <div class="title">{{ flowerName }}</div>

        <div class="section-wrapper">
            <div class="chart-box">
                <GChart
                type="LineChart"
                :data="flowerData"
                :options="chartOptions"
                :events="chartEvents"
                />
            </div>
            <div class="description">Wilgotność: {{ humidity }}%<br>
            Światło: {{ light }}%<br>
            Bateria: <div :class="getWarning(isWarning)">{{  power }}</div>V</div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { GChart } from 'vue-google-charts';

export default {
    props: {
        flowerName: String,
        flowerNumber: Number,
        flowerDays: Number,
    },
    data () {
    return {
        minPowerWarning: 2.6, //below this value there will be a warning !!!
        flowerData: [],
        humidity: 0,
        light: 0,
        power: 0,
        isWarning: false,
        chartOptions: {
          chart: {
            title: 'Energia',
            curveType: 'function',
            legend: { position: 'bottom' },
          },
          colors: ['#a4c6ec','#f0c78b', '#549251'],
          backgroundColor: '#157179',
          legend: 'none',
          hAxis: { textPosition: 'none' },
          chartArea: {width:"80%", height:"90%"},
        }
    }
    },
    methods: ({
        getData() {
            axios.get('https://kosik.dynv6.net/webhooks/getFlowerData.php?days=' + this.flowerDays + '&table=flower'+ this.flowerNumber +'').then(response => (this.flowerData = JSON.parse(JSON.stringify(response.data)), this.refreshData()));
        },
        refreshData () {
            console.log(this.flowerData);
            var lastItem = this.flowerData.pop();

            this.humidity = lastItem[1];
            this.light = lastItem[2];
            this.power = lastItem[3];
            if(this.power < this.minPowerWarning){
                this.isWarning = true;
            }
        },
        getWarning(warn) {
            return warn ? 'warning-text' : 'normal-text';
        }
    }),
    components: {
      GChart,
    },
    created () {
        this.intervalHolder = setInterval(this.getData, 60000);
    },
    mounted () {
        this.getData();
    },
    unmounted () {
      clearInterval(this.intervalHolder);
    },
    watch: {
        flowerDays(newValue, oldValue) {
            this.getData();
        }
    },
    chartEvents: {
        'select': () => {
          // handle event here
        }
      }
}
</script>


<style lang="scss" scoped>
@import "../assets/scss/variables.scss";


.section-wrapper {
    display: grid;
    grid-template-columns: 70% 27%;
    gap: 30px;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
.flower-box {
    padding: 10px;
    text-align: center;
    background-color: $color-0;
    border-radius: 4px;
    border: $border-1 1px solid;
}

.title {
    font-size: 38px;
    text-align: center;
    margin-bottom: 10px;
    color: $color-3;
    border-bottom: $first-bg-color 1px solid;
    line-height: 1.7em;
}

.text {
    padding-left: 10px;
    font-size: 18px;
    color: $color-2;
    line-height: 1.6;
}

.chart-box {
    padding: 0px;
}

.description {
    margin: 2px;
}

.warning-text {
  color: #7e2e0e;
  font-weight: bold;
  display: inline;
}

.normal-text {
  color: #2c3e50;
  display: inline;
}

.test {
    color: #a4c6ec;
}
</style>
  