<template>
    <div class="siteHolder">
      <body>
        <div class="section-wrapper">

          <div class="device-container">
            <div class="title">Zewnetrzna</div>
            <div class="text">Temperatura: {{ sensorOutside.temperature }}°C</div>
            <div class="text2">Wilgotność: {{ sensorOutside.humidity }}%</div>
            <div class="text2">Ciśnienie: {{ sensorOutside.airPressure }} hPa</div>

            <div class="text2">Światło: {{ sensorOutside.light }} lux</div>

            <div class="text2">Wiatr: {{ sensorOutside.windspeed }} kmh</div>
            <div class="text2">Kierunek: {{ sensorOutside.winddirection }}°</div>
            <div class="text2">Bateria: {{ sensorOutside.power }} V</div>
          </div>
          
          <section class="device-container">
            <div class="title">Salon</div>
            <div class="text">Temperatura: {{ sensorSalon.temperature }}°C</div>
            <div class="text2">Wilgotność: {{ sensorSalon.humidity }}%</div>
            <div class="text2">Bateria: {{ sensorSalon.power}}%</div>
          </section>
          
          <section class="device-container">
            <div class="title">Sypialnia</div>
            <div class="text">Temperatura: {{ sensorBedroom.temperature }}°C</div>
            <div class="text2">Wilgotność: {{ sensorBedroom.humidity }}%</div>
          </section>
          
          <div class="tasmota-container"><tasmota /></div>
          
          <div class="device-container">
              <div class="title-container">
                <div class="title">Kuchnia</div>
                <div class="button-settings" @click="openModal(kitchen)">
                  <i class="fa-solid fa-gear fa-xs"></i>
                </div>
              </div>
            <div class="button" @click="sendMsg('kitchenlight.1')" :style="kitchen.flag ? {boxShadow: '0px 0px 11px 1px #3acb29'} : {boxShadow: '-1px 2px 7px 0px #2c3531'}">On</div>
            <div class="button" @click="sendMsg('kitchenlight.0')" :style="kitchen.flag ? {boxShadow: '-1px 2px 7px 0px #2c3531'} : {boxShadow: '0px 0px 11px 1px #f31f1f'}">Off</div>
          </div>
          
          <div class="device-container">
            <div class="title-container">
              <div class="title">Hydroponika</div>
              <div class="button-settings" @click="openModal(hydroponics)">
                <i class="fa-solid fa-gear fa-xs"></i>
              </div>
            </div>
            <div class="button" @click="sendMsg('hydroponics.1')" :style="hydroponics.flag ? {boxShadow: '0px 0px 11px 1px #3acb29'} : {boxShadow: '-1px 2px 7px 0px #2c3531'}">On</div>
            <div class="button" @click="sendMsg('hydroponics.0')" :style="hydroponics.flag ? {boxShadow: '-1px 2px 7px 0px #2c3531'} : {boxShadow: '0px 0px 11px 1px #f31f1f'}">Off</div>
          </div>
          
          <div class="device-container">
            <div class="title-container">
              <div class="title">Flaming</div>
              <div class="button-settings" @click="openModal(decorationFlamingo)">
                <i class="fa-solid fa-gear fa-xs"></i>
              </div>
            </div>
            <div class="button" @click="sendMsg('room2Decorations.1')" :style="decorationFlamingo.flag ? {boxShadow: '0px 0px 11px 1px #3acb29'} : {boxShadow: '-1px 2px 7px 0px #2c3531'}">On</div>
            <div class="button" @click="sendMsg('room2Decorations.0')" :style="decorationFlamingo.flag ? {boxShadow: '-1px 2px 7px 0px #2c3531'} : {boxShadow: '0px 0px 11px 1px #f31f1f'}">Off</div>
          </div>
          
          <div class="device-container">
            <div class="title-container">
              <div class="title">Dekoracje Salon</div>
              <div class="button-settings" @click="openModal(room1Decorations1)">
                <i class="fa-solid fa-gear fa-xs"></i>
              </div>
            </div>
            <div class="button" @click="sendMsg('room1Decorations.1')" :style="room1Decorations1.flag ? {boxShadow: '0px 0px 11px 1px #3acb29'} : {boxShadow: '-1px 2px 7px 0px #2c3531'}">On</div>
            <div class="button" @click="sendMsg('room1Decorations.0')" :style="room1Decorations1.flag ? {boxShadow: '-1px 2px 7px 0px #2c3531'} : {boxShadow: '0px 0px 11px 1px #f31f1f'}">Off</div>
          </div>
          
          <div class="device-container">
            <div class="title-container">
              <div class="title">Pasek LED</div>
              <div class="button-settings" @click="openModal(ledStripe)">
                <i class="fa-solid fa-gear fa-xs"></i>
              </div>
            </div>
            <div class="color-picker" style="background-color: rgb(255, 255, 255);" @click="sendMsg('ledstripecolor.255255255')"></div>
            <div class="color-picker" style="background-color: rgb(255, 251, 198);" @click="sendMsg('ledstripecolor.255251198')"></div>
            <div class="color-picker" style="background-color: rgb(220, 230, 093);" @click="sendMsg('ledstripecolor.220230093')"></div>
            <div class="color-picker" style="background-color: rgb(254, 142, 053);" @click="sendMsg('ledstripecolor.254142053')"></div>
            <div class="color-picker" style="background-color: rgb(254, 115, 053);" @click="sendMsg('ledstripecolor.254115053')"></div>
            <div class="color-picker" style="background-color: rgb(255, 000, 000);" @click="sendMsg('ledstripecolor.255000000')"></div>
            <div class="color-picker" style="background-color: rgb(255, 053, 100);" @click="sendMsg('ledstripecolor.255053100')"></div>
            <div class="color-picker" style="background-color: rgb(168, 164, 226);" @click="sendMsg('ledstripecolor.168164226')"></div>
            <div class="color-picker" style="background-color: rgb(186, 128, 219);" @click="sendMsg('ledstripecolor.186128219')"></div>
            <div class="color-picker" style="background-color: rgb(223, 053, 200);" @click="sendMsg('ledstripecolor.223053200')"></div>
            <div class="color-picker" style="background-color: rgb(162, 068, 130);" @click="sendMsg('ledstripecolor.162068130')"></div>
            <div class="color-picker" style="background-color: rgb(000, 050, 255);" @click="sendMsg('ledstripecolor.000050255')"></div>
            <div class="color-picker" style="background-color: rgb(020, 200, 225);" @click="sendMsg('ledstripecolor.020200225')"></div>
            <div class="color-picker" style="background-color: rgb(053, 254, 150);" @click="sendMsg('ledstripecolor.053254150')"></div>
            <div class="color-picker" style="background-color: rgb(020, 255, 050);" @click="sendMsg('ledstripecolor.020255050')"></div>
            <div class="color-picker" style="background-color: rgb(000, 202, 084);" @click="sendMsg('ledstripecolor.000202084')"></div>
            <input type="range" min="0" max="100" :value="ledStripe.brightness" class="slider" id="ledstripebrightness" @click="sendBrightness('ledstripebrightness')" @touchend="sendBrightness('ledstripebrightness')">
            <div class="button" @click="sendMsg('ledstripebrightness.100')" :style="ledStripe.flag ? {boxShadow: '0px 0px 11px 1px #3acb29'} : {boxShadow: '-1px 2px 7px 0px #2c3531'}">On</div>
            <div class="button" @click="sendMsg('ledstripebrightness.0')" :style="ledStripe.flag ? {boxShadow: '-1px 2px 7px 0px #2c3531'} : {boxShadow: '0px 0px 11px 1px #f31f1f'}">Off</div>
          </div>
          
          <div class="device-container">
            <div class="title">Lampa</div>
            <div class="color-picker" style="background-color: rgb(255, 255, 255);" @click="sendMsg('room1TradfriLampColor.255255255')"></div>
            <div class="color-picker" style="background-color: rgb(255, 251, 198);" @click="sendMsg('room1TradfriLampColor.255251198')"></div>
            <div class="color-picker" style="background-color: rgb(220, 230, 093);" @click="sendMsg('room1TradfriLampColor.220230093')"></div>
            <div class="color-picker" style="background-color: rgb(254, 142, 053);" @click="sendMsg('room1TradfriLampColor.254142053')"></div>
            <div class="color-picker" style="background-color: rgb(254, 115, 053);" @click="sendMsg('room1TradfriLampColor.254115053')"></div>
            <div class="color-picker" style="background-color: rgb(255, 000, 000);" @click="sendMsg('room1TradfriLampColor.255000000')"></div>
            <div class="color-picker" style="background-color: rgb(255, 053, 100);" @click="sendMsg('room1TradfriLampColor.255053100')"></div>
            <div class="color-picker" style="background-color: rgb(168, 164, 226);" @click="sendMsg('room1TradfriLampColor.168164226')"></div>
            <div class="color-picker" style="background-color: rgb(186, 128, 219);" @click="sendMsg('room1TradfriLampColor.186128219')"></div>
            <div class="color-picker" style="background-color: rgb(223, 053, 200);" @click="sendMsg('room1TradfriLampColor.223053200')"></div>
            <div class="color-picker" style="background-color: rgb(162, 068, 130);" @click="sendMsg('room1TradfriLampColor.162068130')"></div>
            <div class="color-picker" style="background-color: rgb(000, 050, 255);" @click="sendMsg('room1TradfriLampColor.000050255')"></div>
            <div class="color-picker" style="background-color: rgb(020, 200, 225);" @click="sendMsg('room1TradfriLampColor.020200225')"></div>
            <div class="color-picker" style="background-color: rgb(053, 254, 150);" @click="sendMsg('room1TradfriLampColor.053254150')"></div>
            <div class="color-picker" style="background-color: rgb(020, 255, 050);" @click="sendMsg('room1TradfriLampColor.020255050')"></div>
            <div class="color-picker" style="background-color: rgb(000, 202, 084);" @click="sendMsg('room1TradfriLampColor.000202084')"></div>
            <input type="range" min="0" max="100" value="0" class="slider" id="room1TradfriLampBrightness" @click="sendBrightness('room1TradfriLampBrightness')" @touchend="sendBrightness('room1TradfriLampBrightness')">
            <div class="button" @click="sendMsg('room1TradfriLampBrightness.100')">On</div>
            <div class="button" @click="sendMsg('room1TradfriLampBrightness.0')">Off</div>
          </div>

          <div class="device-container">
            <div class="title-container">
              <div class="title">LED LEGO</div>
              <div class="button-settings" @click="openModal(ledLego)">
                <i class="fa-solid fa-gear fa-xs"></i>
              </div>
            </div>
            <div class="color-picker" style="background-color: rgb(0, 0, 0);" @click="sendMsg('ledLego.0')"></div>
            <div class="color-picker" style="background-color: rgb(64, 64, 64);" @click="sendMsg('ledLego.25')"></div>
            <div class="color-picker" style="background-color: rgb(128, 128, 128);" @click="sendMsg('ledLego.50')"></div>
            <div class="color-picker" style="background-color: rgb(196, 196, 196);" @click="sendMsg('ledLego.75')"></div>
            <div class="color-picker" style="background-color: rgb(255, 255, 255);" @click="sendMsg('ledLego.100')"></div>
            <input type="range" min="0" max="100" :value="ledLego.brightness" class="slider" id="ledLego" @click="sendBrightness('ledLego')" @touchend="sendBrightness('ledLego')">
            <div class="button" @click="sendMsg('ledLego.100')" :style="ledLego.flag ? {boxShadow: '0px 0px 11px 1px #3acb29'} : {boxShadow: '-1px 2px 7px 0px #2c3531'}">On</div>
            <div class="button" @click="sendMsg('ledLego.0')" :style="ledLego.flag ? {boxShadow: '-1px 2px 7px 0px #2c3531'} : {boxShadow: '0px 0px 11px 1px #f31f1f'}">Off</div>
          </div>

          <div class="device-container">
            <div class="title-container">
              <div class="title">LED Biurka</div>
              <div class="button-settings" @click="openModal(ledDesk)">
                <i class="fa-solid fa-gear fa-xs"></i>
              </div>
            </div>
            <div class="color-picker" style="background-color: rgb(0, 0, 0);" @click="sendMsg('ledDesk.0')"></div>
            <div class="color-picker" style="background-color: rgb(64, 64, 64);" @click="sendMsg('ledDesk.25')"></div>
            <div class="color-picker" style="background-color: rgb(128, 128, 128);" @click="sendMsg('ledDesk.50')"></div>
            <div class="color-picker" style="background-color: rgb(196, 196, 196);" @click="sendMsg('ledDesk.75')"></div>
            <div class="color-picker" style="background-color: rgb(255, 255, 255);" @click="sendMsg('ledDesk.100')"></div>
            <input type="range" min="0" max="100" :value="ledDesk.brightness" class="slider" id="ledDesk" @click="sendBrightness('ledDesk')" @touchend="sendBrightness('ledDesk')">
            <div class="button" @click="sendMsg('ledDesk.100')" :style="ledDesk.flag ? {boxShadow: '0px 0px 11px 1px #3acb29'} : {boxShadow: '-1px 2px 7px 0px #2c3531'}">On</div>
            <div class="button" @click="sendMsg('ledDesk.0')" :style="ledDesk.flag ? {boxShadow: '-1px 2px 7px 0px #2c3531'} : {boxShadow: '0px 0px 11px 1px #f31f1f'}">Off</div>
          </div>

          <div class="device-container">
            <div class="title-container">
              <div class="title">Concorde</div>
              <div class="button-settings" @click="openModal(Concorde)">
                <i class="fa-solid fa-gear fa-xs"></i>
              </div>
            </div>
            <div style="border-bottom: #2c3531 1px solid;">
              <div class="color-picker" style="background-color: rgb(255, 255, 255);" @click="sendMsg('concorde.CFFFFFF')"></div>
              <div class="color-picker" style="background-color: rgb(255, 251, 198);" @click="sendMsg('concorde.CFFFBCC6')"></div>
              <div class="color-picker" style="background-color: rgb(220, 230, 093);" @click="sendMsg('concorde.CDCE65D')"></div>
              <div class="color-picker" style="background-color: rgb(254, 142, 053);" @click="sendMsg('concorde.CFE8E35')"></div>
              <div class="color-picker" style="background-color: rgb(254, 115, 053);" @click="sendMsg('concorde.CFE73035')"></div>
              <div class="color-picker" style="background-color: rgb(255, 000, 000);" @click="sendMsg('concorde.CFF0000')"></div>
              <div class="color-picker" style="background-color: rgb(255, 053, 100);" @click="sendMsg('concorde.CFF3564')"></div>
              <div class="color-picker" style="background-color: rgb(168, 164, 226);" @click="sendMsg('concorde.CA8A4E2')"></div>
              <div class="color-picker" style="background-color: rgb(186, 128, 219);" @click="sendMsg('concorde.CBA80DB')"></div>
              <div class="color-picker" style="background-color: rgb(223, 053, 200);" @click="sendMsg('concorde.CDF35C8')"></div>
              <div class="color-picker" style="background-color: rgb(162, 068, 130);" @click="sendMsg('concorde.CA24482')"></div>
              <div class="color-picker" style="background-color: rgb(000, 050, 255);" @click="sendMsg('concorde.C0032FF')"></div>
              <div class="color-picker" style="background-color: rgb(020, 200, 225);" @click="sendMsg('concorde.C14C8E1')"></div>
              <div class="color-picker" style="background-color: rgb(053, 254, 150);" @click="sendMsg('concorde.C35FE96')"></div>
              <div class="color-picker" style="background-color: rgb(020, 255, 050);" @click="sendMsg('concorde.C14FF32')"></div>
              <div class="color-picker" style="background-color: rgb(000, 202, 084);" @click="sendMsg('concorde.C00CA54')"></div>
              <div class="color-picker" style="background-color: rgb(0, 0, 0);" @click="sendMsg('concorde.P0')"></div>
              <div class="color-picker" style="background-color: rgb(64, 64, 64);" @click="sendMsg('concorde.P25')"></div>
              <div class="color-picker" style="background-color: rgb(128, 128, 128);" @click="sendMsg('concorde.P50')"></div>
              <div class="color-picker" style="background-color: rgb(196, 196, 196);" @click="sendMsg('concorde.P75')"></div>
              <div class="color-picker" style="background-color: rgb(255, 255, 255);" @click="sendMsg('concorde.P100')"></div>
            </div>
            <div class="button" @click="sendMsg('concorde.M7')" :style="Concorde.flag ? {boxShadow: '0px 0px 11px 1px #3acb29'} : {boxShadow: '-1px 2px 7px 0px #2c3531'}">On</div>
            <div class="button" @click="sendMsg('concorde.M5')" :style="Concorde.flag ? {boxShadow: '0px 0px 11px 1px #3acb29'} : {boxShadow: '-1px 2px 7px 0px #2c3531'}">50%</div>
            <div class="button" @click="sendMsg('concorde.M0')" :style="Concorde.flag ? {boxShadow: '-1px 2px 7px 0px #2c3531'} : {boxShadow: '0px 0px 11px 1px #f31f1f'}">Off</div>
          </div>
          
          <div class="device-container">
            <div class="title-container">
              <div class="title">LED Serce</div>
              <div class="button-settings" @click="openModal(ledHeart)">
                <i class="fa-solid fa-gear fa-xs"></i>
              </div>
            </div>
            <div class="color-picker" style="background-color: rgb(0, 0, 0);" @click="sendMsg('ledHeart.0')"></div>
            <div class="color-picker" style="background-color: rgb(64, 64, 64);" @click="sendMsg('ledHeart.25')"></div>
            <div class="color-picker" style="background-color: rgb(128, 128, 128);" @click="sendMsg('ledHeart.50')"></div>
            <div class="color-picker" style="background-color: rgb(196, 196, 196);" @click="sendMsg('ledHeart.75')"></div>
            <div class="color-picker" style="background-color: rgb(255, 255, 255);" @click="sendMsg('ledHeart.100')"></div>
            <input type="range" min="0" max="100" :value="ledHeart.brightness" class="slider" id="ledHeart" @click="sendBrightness('ledHeart')" @touchend="sendBrightness('ledHeart')">
            <div class="button" @click="sendMsg('ledHeart.100')" :style="ledHeart.flag ? {boxShadow: '0px 0px 11px 1px #3acb29'} : {boxShadow: '-1px 2px 7px 0px #2c3531'}">On</div>
            <div class="button" @click="sendMsg('ledHeart.0')" :style="ledHeart.flag ? {boxShadow: '-1px 2px 7px 0px #2c3531'} : {boxShadow: '0px 0px 11px 1px #f31f1f'}">Off</div>
          </div>
          
          <div class="device-container">
            <div class="title-container">
              <div class="title">LED Balkon</div>
              <div class="button-settings" @click="openModal(ledTerrace)">
                <i class="fa-solid fa-gear fa-xs"></i>
              </div>
            </div>
            <div class="color-picker" style="background-color: rgb(0, 0, 0);" @click="sendMsg('ledTerrace.0')"></div>
            <div class="color-picker" style="background-color: rgb(64, 64, 64);" @click="sendMsg('ledTerrace.25')"></div>
            <div class="color-picker" style="background-color: rgb(128, 128, 128);" @click="sendMsg('ledTerrace.50')"></div>
            <div class="color-picker" style="background-color: rgb(196, 196, 196);" @click="sendMsg('ledTerrace.75')"></div>
            <div class="color-picker" style="background-color: rgb(255, 255, 255);" @click="sendMsg('ledTerrace.100')"></div>
            <input type="range" min="0" max="100" :value="ledTerrace.brightness" class="slider" id="ledTerrace" @click="sendBrightness('ledTerrace')" @touchend="sendBrightness('ledTerrace')">
            <div class="button" @click="sendMsg('ledTerrace.100')" :style="ledTerrace.flag ? {boxShadow: '0px 0px 11px 1px #3acb29'} : {boxShadow: '-1px 2px 7px 0px #2c3531'}">On</div>
            <div class="button" @click="sendMsg('ledTerrace.0')" :style="ledTerrace.flag ? {boxShadow: '-1px 2px 7px 0px #2c3531'} : {boxShadow: '0px 0px 11px 1px #f31f1f'}">Off</div>
          </div>

          <div class="device-container">
            <div class="title-container">
              <div class="title">Wtyk USB</div>
              <div class="button-settings" @click="openModal(usbPlug)">
                <i class="fa-solid fa-gear fa-xs"></i>
              </div>
            </div>
            <div class="button" @click="sendMsg('usbPlug.1')" :style="usbPlug.flag ? {boxShadow: '0px 0px 11px 1px #3acb29'} : {boxShadow: '-1px 2px 7px 0px #2c3531'}">On</div>
            <div class="button" @click="sendMsg('usbPlug.0')" :style="usbPlug.flag ? {boxShadow: '-1px 2px 7px 0px #2c3531'} : {boxShadow: '0px 0px 11px 1px #f31f1f'}">Off</div>
          </div>

          <div class="device-container">
            <div class="title">Alarm</div>
            <div class="button" @click="sendMsg('alarmDeactivate.0')">Aktywuj</div>
            <div class="button" @click="sendMsg('alarmDeactivate.300')">Deaktywuj 5 min</div>
            <div class="button" @click="sendMsg('alarmDeactivate.600')">Deaktywuj 10 min</div>
          </div>

          <div v-if="isModalOpen" class="modal-overlay">
            <div class="modal">
              <div class="modal-content">
                <span class="close" @click="closeModal">&times;</span>
                <h2>Ustawienia Urządzenia - {{ currentDevice.name}}</h2>
                <form :key="modalKey" @submit.prevent="sendSettings">
                  <div v-for="(value, key) in filteredDeviceSettings" :key="key">
                    <label :for="key">{{ key }}:</label>
                    <input v-if="typeof value === 'string' || typeof value === 'number'" :type="getType(value)" :id="key" v-model="currentDevice[key]">
                    <input v-if="typeof value === 'boolean'" type="checkbox" :id="key" v-model="currentDevice[key]">
                  </div>
                  <button type="submit">Zapisz</button>
                </form>
              </div>
            </div>
          </div>
        
        </div>
       
     </body>
    </div>
  </template>
  
  <script>
import axios from 'axios'
import tasmota from '@/components/Tasmota.vue';
import variables from './../assets/scss/_variables.scss';
import '@fortawesome/fontawesome-free/css/all.css';

    export default {
      data () {
        return {
          intervalHolder: 0,
          myHomeData: [],
          devicesData: [],
          rxData: "",
          toSend: "",
          sensorSalon: {},
          sensorBedroom: {},
          sensorOutside: {},
          room1Decorations1: {},
          room1Decorations2: {},
          ledStripe: {},
          ledDesk: {},
          ledLego: {},
          ledHeart: {},
          ledTerrace: {},
          Concorde: {},
          usbPlug: {},
          hydroponics: {},
          kitchen: {},
          decorationFlamingo: {},
          flamingo: {},
          isModalOpen: false,
          currentDevice: {},
          modalKey: 0,
        }
      },
      computed: {
        filteredDeviceSettings() {
          if (!this.currentDevice) return {};
          const keysToOmit = ['name', 'flag', 'address', 'error'];
          return Object.fromEntries(
            Object.entries(this.currentDevice).filter(([key]) => !keysToOmit.includes(key))
          );
        }
      },
      methods: ({//getDevicesData
        async sendMsg(msg) {
          this.toSend = 'https://kosik.dynv6.net/webhooks/getdata.php?data=!set^' + msg + '&port=2223';
          console.log("sending msg: " + this.toSend);
          axios.get(this.toSend).then(response => (this.rxData = response.data, console.log(response.data)));
        },
        sendBrightness(device) {
          var sliderVal = document.getElementById(device);
          var txBuf = device + '.' + sliderVal.value;
          this.sendMsg(txBuf);
        },
        getMyHomeData () {
          axios.get('https://kosik.dynv6.net/webhooks/getdata.php?data=!getMyHomeData&port=2223').then(response => (this.myHomeData = JSON.parse(JSON.stringify(response.data)), this.refreshMyHomeData()));
        },
        refreshMyHomeData () {
          this.sensorSalon.temperature = this.get_json_value('Salon', 'temperature', this.myHomeData);
          this.sensorSalon.humidity = this.get_json_value('Salon', 'humidity', this.myHomeData);
          this.sensorSalon.power = this.get_json_value('Salon', 'power', this.myHomeData);
          this.sensorBedroom.temperature = this.get_json_value('Sypialnia', 'temperature', this.myHomeData);
          this.sensorBedroom.humidity = this.get_json_value('Sypialnia', 'humidity', this.myHomeData);

          this.myHomeData.forEach(obj => {
          switch (obj.name) {
              case "sensorOutside":
                this.sensorOutside = obj;
                break;
              default:
                break;
            }
          });
        },
        getDevicesData() {
          axios.get('https://kosik.dynv6.net/webhooks/getdata.php?data=!getDevicesData&port=2223')
            .then(response => {
              this.devicesData = JSON.parse(JSON.stringify(response.data));
              this.refreshDevicesData();

              this.devicesData.forEach(obj => {
                if (obj.name === "LED Desk") {
                  this.ledDesk = obj;
                }
                if (obj.name === "Concorde") {
                  this.Concorde = obj;
                }
                if (obj.name === "LED Lego") {
                  this.ledLego = obj;
                }
                if (obj.name === "LED serce") {
                  this.ledHeart = obj;
                }
                if (obj.name === "LED Terrace") {
                  this.ledTerrace = obj;
                }
                if (obj.name === "USB-Plug") {
                  this.usbPlug = obj;
                }
                if (obj.name === "kitchenLight") {
                  this.kitchen = obj;
                }
                if (obj.name === "decorationFlamingo") {
                  this.decorationFlamingo = obj;
                }
                if (obj.name === "hydroponics") {
                  this.hydroponics = obj;
                }
              });
            });
        },
        refreshDevicesData () {
          console.log(this.devicesData);
          this.room1Decorations1.address = this.get_json_value('Lampa-reka', 'address', this.devicesData);
          this.room1Decorations1.autoBrightness = this.get_json_value('Lampa-reka', 'autoBrightness', this.devicesData);
          this.room1Decorations1.autoLuxMin = this.get_json_value('Lampa-reka', 'autoLuxMin', this.devicesData);
          this.room1Decorations1.autoOn = this.get_json_value('Lampa-reka', 'autoOn', this.devicesData);
          this.room1Decorations1.autoOff = this.get_json_value('Lampa-reka', 'autoOff', this.devicesData);
          this.room1Decorations1.error = this.get_json_value('Lampa-reka', 'error', this.devicesData);
          this.room1Decorations1.flag = this.get_json_value('Lampa-reka', 'flag', this.devicesData);
          this.room1Decorations1.flagManualControl = this.get_json_value('Lampa-reka', 'flagManualControl', this.devicesData);
          
          this.room1Decorations2.address = this.get_json_value('Dekoracje szafka', 'address', this.devicesData);
          this.room1Decorations2.autoBrightness = this.get_json_value('Dekoracje szafka', 'autoBrightness', this.devicesData);
          this.room1Decorations2.autoLuxMin = this.get_json_value('Dekoracje szafka', 'autoLuxMin', this.devicesData);
          this.room1Decorations2.autoOn = this.get_json_value('Dekoracje szafka', 'autoOn', this.devicesData);
          this.room1Decorations2.autoOff = this.get_json_value('Dekoracje szafka', 'autoOff', this.devicesData);
          this.room1Decorations2.error = this.get_json_value('Dekoracje szafka', 'error', this.devicesData);
          this.room1Decorations2.flag = this.get_json_value('Dekoracje szafka', 'flag', this.devicesData);
          this.room1Decorations2.flagManualControl = this.get_json_value('Dekoracje szafka', 'flagManualControl', this.devicesData);

          this.ledStripe.address = this.get_json_value('LED strip', 'address', this.devicesData);
          this.ledStripe.autoBrightness = this.get_json_value('LED strip', 'autoBrightness', this.devicesData);
          this.ledStripe.autoLuxMin = this.get_json_value('LED strip', 'autoLuxMin', this.devicesData);
          this.ledStripe.autoOn = this.get_json_value('LED strip', 'autoOn', this.devicesData);
          this.ledStripe.autoOff = this.get_json_value('LED strip', 'autoOff', this.devicesData);
          this.ledStripe.error = this.get_json_value('LED strip', 'error', this.devicesData);
          this.ledStripe.flag = this.get_json_value('LED strip', 'flag', this.devicesData);
          this.ledStripe.flagManualControl = this.get_json_value('LED strip', 'flagManualControl', this.devicesData);
          this.ledStripe.white = this.get_json_value('LED strip', 'white', this.devicesData);
          this.ledStripe.setting = this.get_json_value('LED strip', 'setting', this.devicesData);
          this.ledStripe.brightness = this.get_json_value('LED strip', 'brightness', this.devicesData);
        },
        get_json_value(name, key, jsonData) {
          for(const sensor in jsonData){
            if(jsonData[sensor]["name"] == name){
              return jsonData[sensor][key];
            }
          }
        },
        zeroPad(num, places) {
          var zero = places - num.toString().length + 1;
          return Array(+(zero > 0 && zero)).join("0") + num;
        },
        openModal(device) {
          this.currentDevice = { ...device };
          this.modalKey += 1;
          this.isModalOpen = true;
        },
        closeModal() {
          this.isModalOpen = false;
          this.currentDevice = {};
        },
        getType(value) {
          return typeof value === 'number' ? 'number' : 'text';
        },
        sendSettings() {
          const jsonData = encodeURIComponent(JSON.stringify(this.currentDevice));
          console.log("sendSettings:")
          console.log(jsonData);
          const url = `https://kosik.dynv6.net/webhooks/getdata.php?data=!setDeviceData.${jsonData}&port=2223`;

          fetch(url, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              }
          })
          .then(response => {
              if (!response.ok) {
                  throw new Error('Network response was not ok');
              }
              return response.json();
          })
          .then(data => {
              if (data.status === 'ok') {
                  console.log('Settings saved successfully!');
              } else {
                  throw new Error('Unexpected server response');
              }
          })
          .catch(error => {
              console.error('Error:', error);
          });
        },
      }),
      components: {
        tasmota,
      },
      created () { /* on load */ 
        this.getMyHomeData();
        this.getDevicesData();
        this.intervalHolder = setInterval(this.getMyHomeData, 5000);
        this.intervalHolder = setInterval(this.getDevicesData, 2000);
      },
      unmounted () {
        clearInterval(this.intervalHolder);
      }
    }
  </script>
  
  
  <style lang="scss" scoped>
  @import "../assets/scss/variables.scss";
    .siteHolder {
      max-width: 1000px;
      margin: auto;
    }

    .section-wrapper {
      display: grid;
      grid-template-columns: 0.33fr 0.33fr 0.33fr;
      gap: 10px;
      @media (max-width: 1000px) {
        grid-template-columns: 0.5fr 0.5fr;
      }
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
    }

    .device-container {
      padding: 10px;
      text-align: center;
      background-color: $color-0;
      border-radius: 4px;
      border: $border-1 1px solid;
      line-height: 1.6;

      .title-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $color-3;
        border-bottom: $first-bg-color 1px solid;
      }

      .title {
        flex: 1;
        font-size: 38px;
        color: $color-3;
      }
      .text {
        font-size: 28px;
        text-align: center;
        color: $color-2;
      }
      .text2 {
        font-size: 18px;
        text-align: center;
        color: $color-2;
      }
      .button {
        color: $first-bg-color;
        font-size: 24px;
        font-weight: 600;
        display: inline-block;
        background-color: $color-2;
        border-radius: 10px;
        margin: 10px;
        cursor: pointer;
        padding: 0px 30px 0px 30px;
        box-shadow: -1px 2px 7px 0px $first-bg-color;
        text-transform: uppercase;
        transition: all 0.2s ease-in-out;
        @media (max-width: 768px) {
          font-size: 20px;
        }

        &:hover {
          background: rgba($color-2, 80%);
        }

        &:active {
          color: rgba(255, 255, 255, 1);
          box-shadow: 0 5px 15px rgba(212, 212, 212, 0.4);
        }  
      }

      .button-settings {
        justify-self: end;
        color: $first-bg-color;
        display: inline-block;
        background-color: #70888b;
        border-radius: 10px;
        cursor: pointer;
        padding: 1px 8px 0px 8px;
        box-shadow: -1px 2px 7px 0px $first-bg-color;
        text-transform: uppercase;
        transition: all 0.2s ease-in-out;
        @media (max-width: 768px) {
          font-size: 20px;
        }

        &:hover {
          background: rgba(#c7d5d7, 80%);
        }
      }

      .color-picker {
        width: 10%;
        padding-top: 10%;
        border-radius: 50%;
        box-shadow: 1px 2px 5px 0px;
        cursor: pointer;
        display: inline-block;
        margin: 3px 3px 3px 3px;
        
        &:active {
          color: rgba(255, 255, 255, 1);
          box-shadow: 0 5px 15px rgba(145, 92, 182, .4);
        } 
      }

      .slider {
        -webkit-appearance: none;
        width: 100%;
        height: 15px;
        border-radius: 5px;  
        background: $first-bg-color;
        outline: none;
        opacity: 0.8;
        margin: 20px 0px 20px 0px;
        transition: opacity .2s;

        &:hover {
          opacity: 1; /* Fully shown on mouse-over */
        }
      }

      .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        border-radius: 50%; 
        background: $color-4;
        cursor: pointer;
      }

      .slider::-moz-range-thumb {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: $color-4;
        cursor: pointer;
      }
  }

  .tasmota-container {
    grid-row-end: span 2;
  }

  .modal-overlay {
  position: fixed; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%; 
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex; 
  justify-content: center; 
  align-items: center;
}

.modal {
  background-color: #fefefe;
  border: 1px solid #888;
  width: 80%; 
  max-width: 600px; 
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-content {
  padding: 20px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
  </style>
  