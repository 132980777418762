<template>
    <div class="siteHolder">
      <body>
        <div class="section-wrapper">

            <div class="select-container">
                <div class="title">Dni:</div> 
                    <select class="chooseBox" v-model="selectedDays" id="days" name="days">
                        <option v-for="option in options" :value="option.value">
                            {{ option.text }}
                        </option>
                </select>
            </div>
            <div class="flower-container"><terraChart v-if="renderComponent" updn="UP" :terraDays="selectedDays"/></div>
            <div class="flower-container"><terraChart v-if="renderComponent" updn="DN" :terraDays="selectedDays"/></div>

        </div>       
     </body>
    </div>
  </template>
  
  <script>
import axios from 'axios'
import variables from './../assets/scss/_variables.scss';
import terraChart from '@/components/TerraChart.vue';

    export default {
      data () {
        return {
          intervalHolder: 0,
          myHomeData: [],
          devicesData: [],
          selectedDays: 7,
          options: [
            { text: "1 dzień", value: 1 },
            { text: "3 dni", value: 3 },
            { text: "7 dni", value: 7 },
            { text: "14 dni", value: 14 },
            { text: "30 dni", value: 30 }
          ],
          renderComponent: true,
        }
      },
      methods: ({
        
      }),
      components: {
        terraChart,
      },
      created () { /* on load */ 
      },
      unmounted () {
      }
    }
  </script>
  
  
  <style lang="scss" scoped>
  @import "../assets/scss/variables.scss";
    .siteHolder {
      max-width: 1000px;
      margin: auto;
    }

    .section-wrapper {
      display: grid;
      grid-template-columns: 0.33fr 0.33fr 0.33fr;
      gap: 10px;
      @media (max-width: 1000px) {
        grid-template-columns: 0.5fr 0.5fr;
      }
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
    }
    .title {
    margin-bottom: 10px;
    font-size: 38px;
    color: $color-3;
    border-bottom: $first-bg-color 1px solid;
    }
    .text {
    font-size: 28px;
    text-align: center;
    color: $color-2;
    }

    .flower-container {
        grid-column: span 4;
        grid-row-end: span 2;
    }

.select-container {
grid-column: 1 / 5;
grid-row-end: span 1;
padding: 10px;
text-align: center;
background-color: $color-0;
border-radius: 4px;
border: $border-1 1px solid;

    .title {
        display: inline;
        margin-bottom: 10px;
        margin-right: 20px;
        padding-right: 20px;
        font-size: 38px;
        color: $color-3;
        border-right: $first-bg-color 1px solid;
        border-bottom: 0px;
      }

    .chooseBox {
      font-size: 24px;
    }
  }
  </style>
  