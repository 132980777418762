<template>
    <div class="siteHolder">
      <body>
        <div class="section-wrapper">

          <div class="device-container">
            <div class="title">Reset</div>
            <div class="button" @click="resetDevice">Wykonaj</div>
          </div>
          
          <div class="device-container" style="grid-row-end: span 2; grid-column-end: span 2">
            <div class="title">Wyślij przez Socket</div>
            <div class="nrf-container">
              <div class="address-container">
                <div class="label">Adres:</div>
                <div class="text3">Warości heksadecymalne</div>
                <div class="hex-container">
                  <textarea class="hexBox" v-model="hex1" placeholder="hex1" maxlength="4" v-on:keydown.tab.prevent="nextTextArea" />
                  <textarea class="hexBox" v-model="hex2" placeholder="hex2" maxlength="4" v-on:keydown.tab.prevent="nextTextArea" />
                  <textarea class="hexBox" v-model="hex3" placeholder="hex3" maxlength="4" v-on:keydown.tab.prevent="nextTextArea" />
                  <textarea class="hexBox" v-model="hex4" placeholder="hex4" maxlength="4" v-on:keydown.tab.prevent="nextTextArea" />
                  <textarea class="hexBox" v-model="hex5" placeholder="hex5" maxlength="4" />
                </div>
              </div>
              <div class="radioButtonContainer">
                <div class="label" style="text-align: left;">Moc:</div>
                <div v-for="option in options" :key="option.id">
                  <input type="radio" :id="option.id" :value="option.value" v-model="power" />
                  <label :for="option.id">{{ option.label }}</label>
                </div>
              </div>
              <div style="grid-column-end: span 2">
                <div class="label">Dane do wysłania:</div>
                <textarea class="editBox" v-model="dataToSend" placeholder="#{adres}{komenda}{dane}" />
              </div>
              <div class="button" style="grid-column-end: span 2" @click="sendToNrf">Wyślij</div>
            </div>
          </div>

        </div>       
     </body>
    </div>
  </template>
  
  <script>
import axios from 'axios'
import variables from './../assets/scss/_variables.scss';

    export default {
      data () {
        return {
          dataToSend: "",
          hex1: "00",
          hex2: "00",
          hex3: "00",
          hex4: "00",
          hex5: "00",
          options: [
            { id: "option1", value: 0, label: "MIN" },
            { id: "option2", value: 1, label: "LOW" },
            { id: "option3", value: 2, label: "HIGH" },
            { id: "option4", value: 3, label: "MAX" }
          ],
          power: 0,
        }
      },
      methods: ({
        async sendMsg(msg) {
          this.toSend = 'https://kosik.dynv6.net/webhooks/getdata.php?data=!set^' + msg + '&port=2223';
          console.log("sending msg: " + this.toSend);
          axios.get(this.toSend).then(response => (this.rxData = response.data, console.log(response.data)));
        },
        resetDevice() {
          this.sendMsg("systemReset.1");
        },
        sendToNrf() {
          var encodedData = this.dataToSend.replace(/#/g, '%23');
          var messagetosend = 'sendToNrf.{"address":[' + parseInt(this.hex1, 16) + ',' + parseInt(this.hex2, 16) + ',' + parseInt(this.hex3, 16) + ',' + parseInt(this.hex4, 16) + ',' + parseInt(this.hex5, 16) + '],' + '"message":"' + encodedData + '", "power":' + this.power + '}';
          console.log(messagetosend);
          this.sendMsg(messagetosend);
        },
        nextTextArea(event) {
          if (event.keyCode === 9) {
            const ref = event.target.nextElementSibling;
            if (ref) {
              ref.focus();
              ref.select();
            }
          }
        },
      }),
      components: {

      },
      created () { /* on load */ 
      },
      unmounted () {
      }
    }
  </script>
  
  
  <style lang="scss" scoped>
  @import "../assets/scss/variables.scss";
    .siteHolder {
      max-width: 1000px;
      margin: auto;
    }

    .section-wrapper {
      display: grid;
      grid-template-columns: 0.33fr 0.33fr 0.33fr;
      gap: 10px;
      @media (max-width: 1000px) {
        grid-template-columns: 0.5fr 0.5fr;
      }
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
    }

    .device-container {
      padding: 10px;
      text-align: center;
      background-color: $color-0;
      border-radius: 4px;
      border: $border-1 1px solid;
      line-height: 1.6;

      .title {
        margin-bottom: 10px;
        font-size: 38px;
        color: $color-3;
        border-bottom: $first-bg-color 1px solid;
      }
      .text {
        font-size: 28px;
        text-align: center;
        color: $color-2;
      }
      .text2 {
        font-size: 18px;
        text-align: center;
        color: $color-2;
      }
      .button {
        color: $first-bg-color;
        font-size: 24px;
        font-weight: 600;
        display: inline-block;
        background-color: $color-2;
        border-radius: 10px;
        margin: 10px;
        cursor: pointer;
        padding: 0px 30px 0px 30px;
        box-shadow: -1px 2px 7px 0px $first-bg-color;
        text-transform: uppercase;
        transition: all 0.2s ease-in-out;
        @media (max-width: 768px) {
          font-size: 20px;
        }

        &:hover {
          background: rgba($color-2, 80%);
        }

        &:active {
          color: rgba(255, 255, 255, 1);
          box-shadow: 0 5px 15px rgba(212, 212, 212, 0.4);
        }  
      }

      .color-picker {
        width: 10%;
        padding-top: 10%;
        border-radius: 50%;
        box-shadow: 1px 2px 5px 0px;
        cursor: pointer;
        display: inline-block;
        margin: 3px 3px 3px 3px;
        
        &:active {
          color: rgba(255, 255, 255, 1);
          box-shadow: 0 5px 15px rgba(145, 92, 182, .4);
        } 
      }

      .slider {
        -webkit-appearance: none;
        width: 100%;
        height: 15px;
        border-radius: 5px;  
        background: $first-bg-color;
        outline: none;
        opacity: 0.8;
        margin: 20px 0px 20px 0px;
        transition: opacity .2s;

        &:hover {
          opacity: 1; /* Fully shown on mouse-over */
        }
      }

      .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        border-radius: 50%; 
        background: $color-4;
        cursor: pointer;
      }

      .slider::-moz-range-thumb {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: $color-4;
        cursor: pointer;
      }
  }

  .nrf-container {
    display: grid;
    grid-template-columns: 0.7fr 0.3fr;
    gap: 10px;
    @media (max-width: 1000px) {
      grid-template-columns: 0.5fr 0.5fr;
    }
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .text3 {
    font-size: 18px;
    text-align: center;
    color: $color-2;
  }

  .address-container {
      padding: 10px;
      text-align: center;
      line-height: 1.6;
  }
  .hex-container {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 10px;
      padding: 10px;
      text-align: center;
      line-height: 1.6;
  }

  .hexBox {
    resize: none;
    font-size: 20px;
    border-radius: 7px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: $first-bg-color;
    background-color: #effbf5;
    line-height: 0.8em;
    padding-top: 15px;
  }
  .editBox {
    font-size: 20px;
    width: 99%;
    border-radius: 7px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: $first-bg-color;
    background-color: #effbf5;
  }

  .radioButtonContainer {
    padding: 20px;
    text-align: left;
  }

  .label {
    font-size: 32px;
    color: $color-2;
    text-align: center;
    margin-bottom: 10px;
    font-weight: 400;
  }

  .button {
    height: 48px;
    border-radius: 4px;
    $b1-color: $color-3;
    background: rgba($b1-color, 50%);
    border: 2px rgba($b1-color, 100%) solid;
    font-size: 35px;
    padding-left: 26px;
    padding-right: 29px;
    cursor: pointer;
    
    &:hover {
      background: rgba($b1-color, 20%);
    }
    :active {
      background-color: #ffffff;
    }      
  }
  </style>
  